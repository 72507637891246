.footer-solution-content {
  display: flex;
  justify-content: space-between;
}

.footer-links-section {
  display: flex;
  flex-direction: column;
}

.footer-links-section ul {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.left-container {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 10px;
  justify-content: flex-end;
  color: #fff;
}

.left-container h5 {
  text-align: start;
}

.media-container {
  display: flex;
  flex: 1;
}

.social-media-links {
  display: flex;
  justify-content: flex-start;
}

.social-media-links a {
  font-size: 18px;
  display: inline-block;
  background: #276955;
  color: #fff;
  line-height: 1;
  padding: 8px 0;
  margin-right: 12px;
  border-radius: 50%;
  text-align: center;
  width: 36px;
  height: 36px;
  transition: 0.3s;
}

.social-media-links a:hover {
  background: #071c15;
  color: #4a90e2;
  text-decoration: none;
  transform: scale(2);
}

.sparkle {
  display: flex;
  justify-content: flex-start !important;
  text-align: start !important;
}

.footer-link {
  justify-content: flex-start;
  margin: 0 !important;
  padding: 2px 0 !important;
}

.footer-link a {
  display: block;
  line-height: 1;
}

.footer-link p {
  color: #999;
  font-size: 13px;
}
