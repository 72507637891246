.product-sidebar {
  position: sticky;
  top: 150px;
  z-index: 2;
  width: 10%;
}

.sidebar-list {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.list-item {
  justify-content: flex-start;
  padding-top: 12px;
  padding-bottom: 12px;
  width: 120px;
  border-left: 1px solid white;
  border-bottom: none;
}

.list-item:hover {
  border-left: 3px solid white;
  border-bottom: none;;
}
.list-item:active {
  border-left: 3px solid white;
  border-bottom: none;;
}

.sidebar-link {
  color: #fff;
  font-size: 16px;
  font-weight: 700;
  padding-left: 16px;
}

.sidebar-link:visited {
  color: #fff;
}

.btn-active:active {
  color:#071C15;
}

.main-logo {
  width: 60%;
}

/* Form */

/* body {
  background: #76b852;
  display: flex;
  min-height: 100vh;
  justify-content: center;
  align-items: center;
} */

.form-container {
  width: 540px;
  background-color: white;
  margin: auto;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2), 0 5px 5px 0 rgba(0, 0, 0, 0.24);
  padding: 10px;
}

.form-header {
  margin: auto;
  color: #071C15 !important;
  padding: 10px;
}

.form-text-top {
  color: #071C15 !important;
  font-size: 28px;
  font-weight: 700;
  line-height: 1.6;
}

.form-text {
  color: #071C15 !important;

}

.free-demo-form {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  padding: 10px;
}

.row-form {
  display: flex;
  flex-direction: column;
}

.success-message {
  font-family: "Roboto", sans-serif;
  background-color: #3f89f8;
  padding: 15px;
  color: white;
}

.form-field {
  margin: 10px 0 10px 0;
  padding: 15px;
  font-size: 16px;
  border: 0;
  font-family: "Roboto", sans-serif;
}

.form-errors {
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  color: red;
  margin-bottom: 15px;
}

input {
  background: #f2f2f2;
}

textarea {
  background: #f2f2f2;
}

.error {
  border-style: solid;
  border: 2px solid #ffa4a4;
}

button {
  background: #4caf50;
  color: white;
  cursor: pointer;
}

.inline-form {
  display: flex;
  justify-content: space-between;
}