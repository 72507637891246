@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:wght@200&display=swap');

.header {
  font-family: Helvetica;
  display: flex;
  align-items: center;
  position: fixed;
  background-color: #0C3024;
  z-index: 3;
  left: 0;
  right: 0;
  height: 100px;
  font-size: 16px;
  /* border: 2px solid blue; */
}

.logo {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 15%;
}

.home-page-content {
  font-family: 'IBM Plex Sans', sans-serif !important;
  font-size: 2em;
  text-align: justify;
  margin: 32px 0;
  line-height: 1.6;
  font-weight: 500;
}

.services-heading {
  font-family: 'IBM Plex Sans', sans-serif !important;
  font-size: 3em;
  text-align: justify;
  margin: 32px 0;
  line-height: 1.6;
  font-weight: 900;
}

.navbar-menu {
  display: flex;
  flex: 1;
  align-items: center;
  margin: auto;
}

.wrapper {
  display: flex;
  justify-content: center;

}

ul {
  display: flex;
  justify-content: space-between;
}

a {
  text-decoration: none;
  color: white;
}

a:hover {
  text-decoration: none;
  color: white;
}

li {
  display: flex;
  margin-left: 30px;
  padding: 6px;
  text-align: center;
  justify-content: center;
}

.contact-button-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 15%;
}

.contact-button {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 6px 12px;
  text-align: center;
  border: 2px solid white;
  margin-right: 120px;
  width: 60%;
}

.contact-button:hover {
  background-color: #4A90E2;
  text-decoration: none;
}

.navbar-menu > ul > li {
  position: relative;
  white-space: nowrap;
  float: left;
}

.navbar-menu a {
  display: block;
  position: relative;
  color: #ddecf5;
  transition: 0.3s;
  font-size: 14px;
}

.navbar-menu a:hover, .navbar-menu .active > a, .navbar-menu li:hover > a {
  color: #a2cce3;
  text-decoration: none;
}

.navbar-menu .drop-down-link ul {
  display: block;
  position: fixed;
  display: flex;
  justify-content: space-around;
  align-items: center;
  top: 100px;
  left: 0;
  right: 0;
  z-index: 99;
  opacity: 0;
  visibility: hidden;
  padding: 24px 0;
  background: rgba(255, 255, 255);
  box-shadow: 0px 0px 30px rgba(7,28,21, 0.9);
  transition: ease all 0.3s;
  width: 100%;
  padding-left: 3em;
  padding-right: 3em;
}

.navbar-menu .drop-down-link:hover > ul {
  opacity: 1;
  visibility: visible;
}

.navbar-menu .drop-down-link li {
  min-width: 180px;
  position: relative;
}

.navbar-menu .drop-down-link li:last-child {
  min-width: 180px;
  position: relative;
}

/* .list {
  background-color: #4A90E2;
} */

/* <hr style="width: 1px; height: 20px; display: inline-block;"> */
hr {
  width: 2px;
  height: 180px;
  display: inline-block;
}

.navbar-menu .drop-down-link ul a {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px 20px;
  font-size: 14px;
  font-weight: 500;
  text-transform: none;
  color: #0C3024;
}

.nav-p-tag {
  margin-top: 16px;
  text-align: center;
}

.navbar-menu .drop-down-link ul a:hover, .navbar-menu .drop-down ul .active > a, .navbar-menu .drop-down ul li:hover > a {
  color: #68A4C4;
}

.navbar-menu .drop-down-link .drop-down-link ul {
  top: 0;
  left: calc(100% - 30px);
}

.navbar-menu.drop-down-link .drop-down-link:hover > ul {
  opacity: 1;
  top: 0;
  left: 100%;
}

.navbar-menu .drop-down-link .drop-down-link > a {
  padding-right: 10px;
  padding-right: 10px;
}

.navbar-menu .drop-down-link .drop-down-link > a:after {
  content: "\eaa0";
  font-family: IcoFont;
  position: absolute;
  right: 15px;
}

.icon-wrapper {
  display: flex;
  margin: auto;
}

/* Home Heading */

.heading-container {
  margin-left: 16px;
  margin-bottom: 16px;
}
.heading-container h2 {
  font-size: 3em !important;
}

.form_row {
  display: flex;
  flex-wrap: wrap;
}

.item-box-form {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  flex-wrap: wrap;
  text-align: start;
  width: 250px;
  border-left: 2px solid white;
  margin: 12px
}

.item-box-form .title_of_form_link {
  font-weight: bolder;
  font-size: 16px;
  color: #fff;
  text-transform: uppercase;
  margin-left: 14px;
}

.item-box-form:hover {
  background-color: #134232;
}


.item-box-form h4 {
  padding-top: 1em;
}

.description_of_form_link {
  font-family: 'IBM Plex Sans', sans-serif !important;
  font-size: 16;
  display: flex;
  text-align: justify;
  flex: 1;
  padding: 1em;
}

.logo-heading {
 font-size: 1.2em !important;
 text-transform: uppercase;
 
}

.logo-text {
  font-family: 'IBM Plex Sans', sans-serif !important;
  font-size: 2em !important;
}

.float-inUp {
  animation: fadeInUp;
  animation-duration: 1s;
  animation-fill-mode: both;
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translate3d(0, 100%, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.float-inDown {
  animation: fadeinDown;
  animation-duration: 1s;
  animation-fill-mode: both;
}

@keyframes fadeinDown {
  from {
    opacity: 0;
    transform: translate3d(0, 100%, 0,);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}
