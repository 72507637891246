.img-wrapper {
  display: flex;
  justify-content: center;
}

.finance-panel-content {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  font-family: 'IBM Plex Sans', sans-serif !important;
  font-size: 1.5em !important;
  font-weight: 600;
  border-left: 2px solid #2b542c;
  border-right: 2px solid #2b542c;
  border-bottom: 2px solid #2b542c;
  align-items: center;
  padding: 1em 0.5em;
}
