@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:wght@200&display=swap');

.product-sidebar {
  position: sticky;
  top: 130px;
  z-index: 2;
  width: 15%;
}

.sidebar-list {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.list-item {
  justify-content: center;
  padding-top: 12px;
  padding-bottom: 12px;
  width: 100px;
  border-left: 1px solid white;
  border-bottom: none;
}

.list-item:hover {
  border-left: 3px solid white;
  border-bottom: none;;
}
.list-item:active {
  border-left: 3px solid white;
  border-bottom: none;;
}

.sidebar-link {
  color: #fff;
  font-size: 16px;
  font-weight: 700;
  padding-left: 16px;
}

.sidebar-link:visited {
  color: #fff;
}

.btn-active:active {
  color:#071C15;
}

#overview-heading>h2 {
  display: flex;
  color: rgb(255, 255, 255);
  font-family: 'IBM Plex Sans', sans-serif !important;
  font-size: 2em !important;
  margin-bottom: 12px;
}

.overview-text {
  display: flex;
  text-align: start;
  font-family: 'Raleway', sans-serif;
  color: rgb(255, 255, 255);
  font-size: 14px;
}

.main-text {
    margin: 0 !important;
    font-size: 50pt;
    font-weight: 600;
}

.about-section {
  display: flex;
  flex-direction: column;
}

.block {
  margin-left: 15%;
  margin-right: 15%;
}

/* Industries */
.product-sidebar {
  position: sticky;
  top: 130px;
  z-index: 2;
  width: 15%;
}

.sidebar-list {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.list-item {
  justify-content: flex-start;
  padding-top: 12px;
  padding-bottom: 12px;
  width: 150px;
  border-left: 1px solid white;
  border-bottom: none;
}

.list-item:hover {
  border-left: 3px solid white;
  border-bottom: none;;
}

.list-item:active {
  border-left: 3px solid white;
  border-bottom: none;;
}

.sidebar-link {
  color: #fff;
  font-size: 16px;
  font-weight: 700;
  padding-left: 16px;
}

.sidebar-link:visited {
  color: #fff;
}

.btn-active:active {
  color:#071C15;
}

#overview-heading {
  display: flex;
  color: rgb(255, 255, 255);
  font-family: 'IBM Plex Sans', sans-serif !important;
  font-size: 3em;
  font-weight: bold;
  margin-bottom: 12px;
}

.overview-text {
  display: flex;
  font-family: 'Raleway', sans-serif;
  color: rgb(255, 255, 255);
  font-size: 2em;
}

.main-text {
    margin: 0 !important;
    font-weight: 600;
}

.about-section {
  display: flex;
  flex-direction: column;
}

.middle-section {
  display: flex;
  flex-direction: column;
  background-color: #fff !important;
}

.block {
  margin-left: 15%;
  margin-right: 15%;
}

.panel-block {
  display: flex;
  justify-content: space-between;
  border: 2px solid #2b542c;
}

.panel-block span {
  display: flex;
  font-family: 'IBM Plex Sans', sans-serif !important;
  font-size: 1.5em !important;
  font-weight: 600;
  color: #fff;
  background-color: #276955;
  border-right: 2px solid #2b542c;
  justify-content: center;
  align-items: center;
  width: 25%;
  padding: 0.5em;
}

.panel-block span:last-child {
  border-right: none;
}

.panel-block span:hover {
  background-color: rgb(99, 179, 135);
  color: #fff;
}

.product-panel-block {
  display: flex;
  justify-content: space-between;
}

.product-panel-block span {
  display: flex;
  font-family: 'IBM Plex Sans', sans-serif !important;
  font-size: 1.5em !important;
  font-weight: 600;
  color: #fff;
  background-color: #276955;
  border-right: 2px solid #2b542c;
  justify-content: center;
  align-items: center;
  width: 50%;
  padding:0.5em;
}

.product-panel-block span:last-child {
  border-right: none;
}

.product-panel-block span:hover {
  background-color: rgb(99, 179, 135);
  color: #fff;
}

.p-tag {
  display: flex;
  text-align: start;
}


.p-tag p {
  font-family: 'IBM Plex Sans', sans-serif !important;
  font-size: 1em;
  font-weight: bold;
  margin-left: 3em;
}


.key-issues-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.key-issues {
  display: flex;
  align-items: center;
  margin: 16px;
  width: 45%;
}

#spacing {
  margin-left: 8px;
}

.icon-wrapper {
  display: flex;
  margin-right: 16px;
}

.content-section {
  display: flex;
  margin: 3em 0;
}

.content-section:first-child {
  flex-direction: column;
  align-items: center;
}

.text-box-left {
  margin-right: 3em;
}

.image-wrapper{
  display: flex;
  width: 50% !important;
  margin: auto;
}

.main-images {
  width: 320px;
  height: 300px;
  margin: auto;
}

.box-wrapper {
  display: flex;
}

.inside-content-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.inside-content-text {
  display: flex;
  text-align: start;
  font-family: 'Raleway', sans-serif;
  color: rgb(255, 255, 255);
  font-size: 2em;
}