
.new-panel-block {
  display: flex;
  justify-content: space-between;
}

.new-panel-block span {
  display: flex;
  font-family: 'IBM Plex Sans', sans-serif !important;
  font-size: 1.5em !important;
  font-weight: 600;
  color: #fff;
  background-color: #276955;
  border-right: 2px solid #2b542c;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding:0.5em;
}

.telecom-panel-content {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  font-family: 'IBM Plex Sans', sans-serif !important;
  font-size: 1.5em !important;
  font-weight: 600;
  border-left: 2px solid #2b542c;
  border-right: 2px solid #2b542c;
  border-bottom: 2px solid #2b542c;
  align-items: center;
  padding: 1em 0.5em;
}
