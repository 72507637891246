.global-class-name {
  width: 30px;
  height: 30px;
  /* border: 1px solid blue; */
}

.content-wrapper {
  display:  flex;
  align-items: center;
  bottom: 50px;
  position: absolute;
}

@media screen and (max-width: 400px) {
  #features {
    padding: 20px;
    width: 100%;
  }
    #Products,
    #nextstep,
    #footer {
    width: 100%;
  }

  #portfolio {
    width: 100%;
  }
}
